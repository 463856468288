// -----------------------constants------------------------------------
export const SITE_MODE = "localhost"; //localhost, prod
export const DEBUG_MODE = true;
export const SYSTEM_ERROR = "System error";

export const LIKERT_OPTION_COUNT = 5;
export const STATUS_VALUE_LIST = [
  { value: 0, label: "Inactive" },
  { value: 1, label: "Active" },
];
export const STATUS_VALUE_GROUP = [
  { value: "INACTIVE", label: "Inactive" },
  { value: "ACTIVE", label: "Active" }
]
export const USER_ROLE_VALUE_LIST = [
  { value: "Manager", label: "Manager" },
  { value: "Worker", label: "Worker" },
  { value: "Trusted Consultant", label: "Trusted Consultant" }
];
export const GENDER_VALUE_LIST = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
];
export const TARGETING_POSITION_LIST = [
  { value: "General content", label: "General content" },
  { value: "Priority content", label: "Priority content" },
  { value: "Support resources", label: "Support resources" },
  { value: "Highlight", label: "Highlight" },
];
export const EMPLOYMENT_TYPE_LIST = [
  { value: "Employee", label: "Employee" },
  { value: "Contractor", label: "Contractor" },
  { value: "Consultant", label: "Consultant" },
  { value: "Other", label: "Other" },
];
export const STATEMENT_FRAMING_LIST = [
  { value: "Positive", label: "Positive" },
  { value: "Negative", label: "Negative" },
];
export const EFFORT_ACTIVITY_LIST = ["Survey", "Module", "Priority"];
export const EFFORT_TYPE_LIST = ["Self-paced", "Facilitated"];
export const CR_CONTENT_TYPE_LIST = [
  { value: "Survey", label: "Survey" },
  { value: "Module", label: "Module" },
];

export const OBJECT_OPTIONS = {
  1: [{ name: "Company", code: "company" }],
  2: [{ name: "User", code: "user" }],
  3: [
    { name: "Personal Index", code: "personal_index" },
    { name: "Shout Out", code: "shout_out" },
    { name: "Module", code: "module" },
    { name: "Location", code: "location" },
    { name: "Survey", code: "survey" },
    { name: "Video", code: "video" },
  ],
  4: [{ name: "Index", code: "index" }],
};

export const CONDITION_OPTIONS = {
  1: [
    { name: "Is", code: "is", object: "company" },
    { name: "Is not", code: "is_not", object: "company" },
  ],
  2: [
    { name: "Is", code: "is", object: "user" },
    { name: "Is not", code: "is_not", object: "user" },
  ],
  3: [
    { name: "Received", code: "received", object: "shout_out" },
    { name: "Is", code: "is", object: "personal_index" },
    { name: "Is not", code: "is_not", object: "personal_index" },
    { name: "Is", code: "is", object: "survey" },
    { name: "Is Not Completed", code: "is_not_completed", object: "survey" },
    { name: "Is Completed", code: "is_completed", object: "survey" },
    { name: "Is Completed", code: "is_completed", object: "module" },
    {
      name: "Is Not Completed",
      code: "is_not_completed",
      object: "module",
    },
    {
      name: "Enter",
      code: "enter",
      object: "location",
    },
    {
      name: "Leave",
      code: "leave",
      object: "location",
    },
    { name: "Is Completed", code: "is_completed", object: "video" },
    {
      name: "Is Not Completed",
      code: "is_not_completed",
      object: "video",
    },
  ],
  4: [
    { name: "Is less than", code: "is_less_than" },
    { name: "Equal", code: "equal" },
    { name: "Is more than", code: "is_more_than" },
  ],
};

export const QUALITY_OPTIONS = [
  "0",
  "5",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
  "60",
  "65",
  "70",
  "75",
  "80",
  "85",
  "90",
  "95",
  "100",
];
export const LIKERT_SCALE_POSITIVE_OPTIONS = [
  "Strongly agree",
  "Agree",
  "Undecided",
  "Disagree",
  "Strongly disagree",
];
export const LIKERT_SCALE_NEGATIVE_OPTIONS = [
  "Strongly disagree",
  "Disagree",
  "Undecided",
  "Agree",
  "Strongly agree",
];

export const ROLES_ADMIN = [
  {
    name: "CR Admin",
    value: "SUPER_ADMIN",
  },
  {
    name: "Client Admin",
    value: "CLIENT_ADMIN",
  }
]

export const EMPLOYEE_ROLE = [
  {
    label: "Freemium",
    value: "Freemium",
  },
  {
    label: "Manager",
    value: "Manager",
  },
  {
    label: "Worker",
    value: "Worker",
  },
  {
    label: "Trusted consultant",
    value: "Trusted consultant",
  },
  {
    label: "Super admin",
    value: "SUPER_ADMIN",
  }
]

export const EMPLOYEE_ACCESS = [
  {
    label: "Mobile app",
    value: "Mobile app",
  },
  {
    label: "Content creator",
    value: "Content creator",
  },
  {
    label: "Mobile app and content creator",
    value: "Mobile app and content creator",
  }
]

export const MASTER_ADMIN = 'MASTER_ADMIN'
export const CLIENT_ADMIN = 'CLIENT_ADMIN'
export const TEMPLATE_TYPES = {
  MODULE: 'MODULE',
  SURVEY: 'SURVEY',
  PROGRAM: 'PROGRAM'
}
export const DOWNLOAD_IOS = 'https://apps.apple.com/au/app/capdex/id1630742981'
export const DOWNLOAD_ANDROID = 'https://play.google.com/store/apps/details?id=com.capacity.cultureradar'
export const CONTENT_STATUS = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  QUEUED: 'QUEUED'
}