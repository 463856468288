import axios from "axios";
import { baseUrl } from "../config";
import { axiosDelete, axiosGet, axiosPatch, axiosPost, axiosPut } from "./ajax";

export const GetCompaniesList = (params = {}) => {
  const url = `${baseUrl}extras/companyList`;
  return axiosGet(url, params);
};

export const GetSurveyList = (values) => {
  const url = `${baseUrl}surveys/`;
  const params = {
  ...values
  }
  return axiosGet(url, params);
};

export const GetSurveyById = (id) => {
  const url = `${baseUrl}surveys/${id}`;
  const params = {};
  return axiosGet(url, params);
};

export const delSurveyList = (list) => {
  const url = `${baseUrl}surveys/deleteMultiSurvey`;
  const params = {
    surveyIds: list,
  };
  return axiosDelete(url, params);
};

export const delStatement = (list) => {
  const url = `${baseUrl}statement/deleteMultiStatement`;
  const params = {
    statementIds: list,
  };
  return axiosDelete(url, params);
};
export const UpdateSurvey = (values) => {
  const url = `${baseUrl}surveys/updateSurvey/${values.surveyId}`;
  const params = {
    surveyInfo: {
      ...values,
      jobCategory: JSON.stringify(values.jobCategory),
      jobTitle: JSON.stringify(values.jobTitle),
      targeting_users: JSON.stringify(values.targeting_users),
      effort: JSON.stringify(values.effort),
    },
  };
  return axiosPut(url, params);
};

// get index by type
export const getIndexByType = (type) => {
  const url = `${baseUrl}index/indexByType/${type}`;
  const params = {};
  return axiosGet(url, params);
};
export const getIndexByTypeAndCompany = (type, companyId) => {
  const url = `${baseUrl}index/indexByTypeAndCompany/${type}?companyId=${companyId}`;
  const params = {};
  return axiosGet(url, params);
};

export const getIndexByTypeAndCompanyRingo = (type, companyId, selectedSurvey) => {
  const url = `${baseUrl}index/indexByTypeAndCompanyRingo/${type}?companyId=${companyId}&selectedSurvey=${selectedSurvey}`;
  const params = {};
  return axiosGet(url, params);
};

export const getIndex = () => {
  const url = `${baseUrl}index/`;
  const params = {};
  return axiosGet(url, params);
};

export const getIndexChart = (params = {}) => {
  const url = `${baseUrl}index/chart-data`;
  return axiosGet(url, params);
};

export const getIndexChartReport = (params = {}) => {
  const url = `${baseUrl}index/chart-data-report`;
  return axiosGet(url, params);
};

export const getIndexTree = (params = {}) => {
  const url = `${baseUrl}index/index-tree`;
  return axiosGet(url, params);
};

// add statement
export const addStatement = (values) => {
  const url = `${baseUrl}statement/`;
  const params = { ...values };
  return axiosPost(url, params);
};

export const getAllStatements = () => {
  const url = `${baseUrl}statement/`;
  const params = {};
  return axiosGet(url, params);
};

export const getStatementById = (id) => {
  const url = `${baseUrl}statement/${id}`;
  const params = {};
  return axiosGet(url, params);
};
export const updateStatement = (values) => {
  const url = `${baseUrl}statement/${values.statementId}`;
  const params = {
    ...values,
  };
  return axiosPut(url, params);
};
export const addValues = (values) => {
  const url = `${baseUrl}values/`;
  const params = {
    ...values,
  };
  return axiosPost(url, params);
};
export const updateValues = (values) => {
  const url = `${baseUrl}values/${values.valueId}`;
  const params = {
    ...values,
  };
  return axiosPut(url, params);
};
export const updateCompany = (values) => {
  const url = `${baseUrl}company/updateCompany/${values.companyId}`;
  const params = {
    ...values,
  };
  return axiosPost(url, params);
};
export const getCompanyById = (id) => {
  const url = `${baseUrl}company/${id}`;
  const params = {};
  return axiosGet(url, params);
};

// get companies for table
export const GetCompanies = (params = {}) => {
  const url = `${baseUrl}company/`;
  return axiosGet(url, params);
};

export const getCompanyBYUser = (id, params = {}) => {
  const url = `${baseUrl}company/byUser/${id}`;
  return axiosGet(url, params);
};

export const SetCurrentCompany = (companyId) => {
  //quan code
  const url = `${baseUrl}extras/setCurrentCompany`;
  const params = {
    companyId: companyId,
  };
  return axiosPost(url, params);
};
export const deleteMultiCompany = (list) => {
  const url = `${baseUrl}company/deleteMultiCompany/`;
  const params = {
    companyIds: list,
  };
  return axiosDelete(url, params);
};

export const GetQuestionsList = () => {
  const url = `${baseUrl}extras/questions`;
  const params = {};
  return axiosGet(url, params);
};

export const GetValueList = (params = {}) => {
  const url = `${baseUrl}values/`;
  return axiosGet(url, params);
};

export const getValues = (params = {}) => {
  const url = `${baseUrl}values/list`;
  return axiosGet(url, params);
};

export const delValueList = (list) => {
  const url = `${baseUrl}values/deleteMultiValue`;
  const params = {
    valueIds: list,
  };
  return axiosDelete(url, params);
};

export const getValueById = (id) => {
  const url = `${baseUrl}values/${id}`;
  const params = {};
  return axiosGet(url, params);
};
// rewards services
export const addReward = (values) => {
  const url = `${baseUrl}reward/`;
  const params = { ...values };
  return axiosPost(url, params);
};

export const updateReward = (values) => {
  const url = `${baseUrl}reward/${values.rewardId}`;
  const params = {
    ...values,
  };
  return axiosPut(url, params);
};
export const getRewardById = (id) => {
  const url = `${baseUrl}reward/getRewardById/${id}`;
  const params = {};
  return axiosGet(url, params);
};
export const GetRewardList = (params = {}) => {
  const url = `${baseUrl}reward/`;
  return axiosGet(url, params);
};

export const getRewards = (params = {}) => {
  const url = `${baseUrl}reward/list`;
  return axiosGet(url, params);
};

export const deleteMultiReward = (list) => {
  const url = `${baseUrl}reward/deleteMultiReward`;
  const params = {
    rewardIds: list,
  };
  return axiosDelete(url, params);
};
// indexes services
export const addIndex = (values) => {
  const url = `${baseUrl}index/`;
  const params = { ...values };
  return axiosPost(url, params);
};

export const updateIndex = (values) => {
  const url = `${baseUrl}index/${values.valueId}`;
  const params = {
    ...values,
  };
  return axiosPut(url, params);
};

export const GetIndexList = (params = {}) => {
  const url = `${baseUrl}index/`;
  return axiosGet(url, params);
};

export const delIndexList = (list) => {
  const url = `${baseUrl}index/deleteMultiIndex`;
  const params = {
    indexIds: list,
  };
  return axiosDelete(url, params);
};

export const getIndexById = (id) => {
  const url = `${baseUrl}index/${id}`;
  const params = {};
  return axiosGet(url, params);
};

export const GetIndexListByType = (type, current_id = 0) => {
  const url = `${baseUrl}index/get-list`;
  const params = { type: type, current_id: current_id };
  return axiosGet(url, params);
};

export const GetIndexListByParentId = (parent_id, current_id = 0) => {
  const url = `${baseUrl}index/get-list`;
  const params = { parent_id: parent_id, current_id: current_id };
  return axiosGet(url, params);
};

// Modules Services
export const addModule = (values) => {
  const url = `${baseUrl}modules/addModule/`;
  const params = {
    moduleInfo: {
      ...values,
      jobCategory: JSON.stringify(values.jobCategory),
      jobTitle: JSON.stringify(values.jobTitle),
      targeting_users: JSON.stringify(values.targeting_users),
      effort: JSON.stringify(values.effort),
    },
  };
  return axiosPost(url, params);
};
export const addNotification = (values) => {
  const url = `${baseUrl}notifications/addPushNotification`;
  const params = {
    ...values,
    jobCategory: JSON.stringify(values.jobCategory),
    jobTitle: JSON.stringify(values.jobTitle),
    targeting_users: JSON.stringify(values.targeting_users),
  };
  return axiosPost(url, params);
};

export const delNotificationList = (list) => {
  const url = `${baseUrl}notifications/deleteMultiNotifications`;
  const params = {
    notificationIds: list,
  };
  return axiosDelete(url, params);
};

export const GetnotificationList = (params = {}) => {
  const url = `${baseUrl}notifications/`;
  return axiosGet(url, params);
};

export const getnotificationById = (id) => {
  const url = `${baseUrl}notifications/${id}`;
  const params = {};
  return axiosGet(url, params);
};

export const updateNotification = (values) => {
  const url = `${baseUrl}notifications/updateNotification/${values.notificationId}`;
  const params = {
    ...values,
    jobCategory: JSON.stringify(values.jobCategory),
    jobTitle: JSON.stringify(values.jobTitle),
    targeting_users: JSON.stringify(values.targeting_users),
  };
  return axiosPost(url, params);
};

export const sendNotification = (values) => {
  const url = `${baseUrl}notifications/push-notification`;
  const params = {
    notificationId: values,
  };
  return axiosPost(url, params);
};

export const updateModule = (values) => {
  const url = `${baseUrl}modules/updateModule/${values.moduleId}`;
  const params = {
    moduleInfo: {
      ...values,
      jobCategory: JSON.stringify(values.jobCategory),
      jobTitle: JSON.stringify(values.jobTitle),
      targeting_users: JSON.stringify(values.targeting_users),
      effort: JSON.stringify(values.effort),
    },
  };
  return axiosPut(url, params);
};

export const GetModuleList = (values) => {
  const url = `${baseUrl}modules/`;
  const params = {
    ...values,
    isProgramSelect: values?.isProgramSelect,    
  }
  return axiosGet(url, params);
};

export const delModuleList = (list) => {
  const url = `${baseUrl}modules/deleteMultiModule`;
  const params = {
    moduleIds: list,
  };
  return axiosDelete(url, params);
};

export const getModuleById = (id) => {
  const url = `${baseUrl}modules/${id}`;
  const params = {};
  return axiosGet(url, params);
};
export const getModuleByCompany = (id) => {
  const url = `${baseUrl}modules/moduleByCompany/${id}`;
  const params = {};
  return axiosGet(url, params);
};

export const getModuleByMultipleCompany = (array) => {
  const url = `${baseUrl}modules/by-multiple-company`;
  const params = { companyIds: array }
  return axiosGet(url, params)
}

// Priority Modules Services
export const updatePriorityModule = (values) => {
  const url = `${baseUrl}modules/updateModule/${values.moduleId}`;
  const params = {
    moduleInfo: {
      ...values,
      jobCategory: JSON.stringify(values.jobCategory),
      jobTitle: JSON.stringify(values.jobTitle),
      targeting_users: JSON.stringify(values.targeting_users),
      effort: JSON.stringify(values.effort),
    },
  };
  return axiosPut(url, params);
};

export const GetPriorityModuleList = (params = {}) => {
  const url = `${baseUrl}modules/priorityModules`;
  return axiosGet(url, params);
};

export const getPriorityModuleById = (id) => {
  const url = `${baseUrl}modules/priorityModule/${id}`;
  const params = {};
  return axiosGet(url, params);
};
export const getPriorityModuleByCompany = (id) => {
  const url = `${baseUrl}modules/priorityModuleByCompany/${id}`;
  const params = {};
  return axiosGet(url, params);
};

// user services
export const addUser = (values) => {
  const url = `${baseUrl}client/addUser`;
  const params = { ...values };
  return axiosPost(url, params);
};
export const updateUser = (values) => {
  const url = `${baseUrl}client/updateUser/${values.userId}`;
  const params = { ...values };
  return axiosPost(url, params);
};
export const getClientByID = (id) => {
  const url = `${baseUrl}client/getClientByID/${id}`;
  const params = {};
  return axiosGet(url, params);
};
export const getUsers = (params = {}) => {
  const url = `${baseUrl}client`;
  return axiosGet(url, params);
};
export const getUsersBYCompany = (id = 0) => {
  const url = `${baseUrl}client/getUsers/${id}`;
  const params = {};
  return axiosGet(url, params);
};
export const getUsersBYManager = (id = 0) => {
  const url = `${baseUrl}client/getUsersOfManager/${id}`;
  const params = {};
  return axiosGet(url, params);
};
export const deleteMultiClients = (list) => {
  const url = `${baseUrl}client/deleteMultipleUsers`;
  const params = {
    userIds: list,
  };
  return axiosDelete(url, params);
};

// uploadImageFile

export const uploadImageFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const url = `${baseUrl}upload/image`;
  return axiosPost(url, formData);
};

// uploadAudioFile

export const bulkUserUpload = (file, company) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("company", company);
  const url = `${baseUrl}client/bulkUserUpload`;
  return axiosPost(url, formData, null, true);
};

export const uploadAudioFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const url = `${baseUrl}upload/audio`;
  return axiosPost(url, formData);
};

export const uploadPDFFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const url = `${baseUrl}upload/pdf`;
  return axiosPost(url, formData);
};

export const uploadVideoFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const url = `${baseUrl}upload/video`;
  return axiosPost(url, formData);
};


export const getItem = (key) => {
  try {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    }
    return null;
  } catch {
    // clearStorage();
    return null;
  }
};

export const setItem = (key, data) => {
  try {
    return localStorage.setItem(key, JSON.stringify(data));
  } catch {
    // clearStorage();
    return null;
  }
};

// tags

export const addTag = (values) => {
  const url = `${baseUrl}tags/`;
  const params = { ...values };
  return axiosPost(url, params);
};

export const updateTag = (values) => {
  const url = `${baseUrl}tags/${values.tagId}`;
  const params = {
    ...values,
  };
  return axiosPut(url, params);
};
export const getTagById = (id) => {
  const url = `${baseUrl}tags/${id}`;
  const params = {};
  return axiosGet(url, params);
};
export const GetTagList = (values) => {
  const params = {
    ...values,
  };
  const url = `${baseUrl}tags/`;
  return axiosGet(url, params);
};

export const deleteMultiTags = (list) => {
  const url = `${baseUrl}tags/deleteMultipleTags`;
  const params = {
    tagIds: list,
  };
  return axiosDelete(url, params);
};

export const getIndustryList = (parent_id = 0) => {
  const url = `${baseUrl}extras/getIndustryList/${parent_id}`;
  const params = {};
  return axiosGet(url, params);
};

export const getManagerList = (user_id = 0) => {
  let companyName = "";
  if (user_id) {
    user_id = parseInt(user_id);
  } else {
    user_id = 0;
  }

  const url = `${baseUrl}client/getManagerList`;
  const params = {
    user_id: user_id,
    companyName: companyName,
  };
  return axiosGet(url, params);
};

export const getTargetingRelationData = (values) => {
  const url = `${baseUrl}extras/getTargetingRelationData`;
  const params = {
    ...values,
  };
  return axiosPost(url, params);
};

// question
export const getQuestionById = (id) => {
  const url = `${baseUrl}question/${id}`;
  const params = {};
  return axiosGet(url, params);
};

export const addQuestion = (values) => {
  const url = `${baseUrl}question/`;
  const params = { ...values };
  return axiosPost(url, params);
};

export const getAllQuestions = (params = {}) => {
  const url = `${baseUrl}question/`;
  return axiosGet(url, params);
};

export const updateQuestion = (values) => {
  const url = `${baseUrl}question/${values.questionId}`;
  const params = {
    ...values,
  };
  return axiosPut(url, params);
};
export const delQuestion = (list) => {
  const url = `${baseUrl}question/deleteMultiQuestion`;
  const params = {
    questionIds: list,
  };
  return axiosDelete(url, params);
};

export const getCrContentList = (content_type) => {
  const url = `${baseUrl}extras/getCrContentList`;
  const params = {
    content_type: content_type,
  };
  return axiosPost(url, params);
};

export const exportDataAdmin = (value) => {
  const url = `${baseUrl}extras/export`;
  return axiosPost(url, value);
};

export const createLogicBuilder = (value) => {
  const url = `${baseUrl}logic-builder`;
  return axiosPost(url, value);
};

export const getLogicBuilderList = (params = {}) => {
  const url = `${baseUrl}logic-builder`;
  return axiosGet(url, params);
};

export const getLogicBuilderById = (id) => {
  const url = `${baseUrl}logic-builder/${id}`;
  const params = {};
  return axiosGet(url, params);
};

export const updateLogicBuilderById = (id, value) => {
  const url = `${baseUrl}logic-builder/${id}`;
  return axiosPut(url, value);
};

export const addLocation = (value) => {
  const url = `${baseUrl}location/`;
  return axiosPost(url, value);
};

export const updateLocation = (id, value) => {
  const url = `${baseUrl}location/${id}`;
  return axiosPut(url, value);
};

export const getLocationList = (value) => {
  const url = `${baseUrl}location/`;
  const params = {
    ...value,
  };
  return axiosGet(url, params);
};

export const delLogicBuilderList = (list) => {
  const url = `${baseUrl}logic-builder/delete-logic-builders`;
  const params = {
    logicBuilderIds: list,
  };
  return axiosDelete(url, params);
};

export const getProfile = () => {
  const url = `${baseUrl}admin/profile`;
  const params = {};
  return axiosGet(url, params);
};

export const getLocationDetail = (id) => {
  const url = `${baseUrl}location/${id}`;
  const params = {};
  return axiosGet(url, params);
};

export const deleteListLocation = (list) => {
  const url = `${baseUrl}location/deleteMultiLocation`;
  const params = {
    locationIds: list,
  };
  return axiosDelete(url, params);
};

export const changePassword = (data) => {
  const url = `${baseUrl}admin/change-password`;
  return axiosPost(url, data);
};

export const updateProfile = (data) => {
  const url = `${baseUrl}admin/profile`;
  return axiosPost(url, data, "Password incorrect!");
};

export const createAdmin = (data) => {
  const url = `${baseUrl}admin/`;
  return axiosPost(url, data,);
};

export const createEmployee = (data) => {
  const url = `${baseUrl}employee/create`;
  return axiosPost(url, data,);
};
export const updateEmployee = (params) => {
  const url = `${baseUrl}employee/update`;
  return axiosPut(url, params);
}

export const updateEmployeePinAndPassword = (params) => {
  const url = `${baseUrl}employee/updatePassword`;
  return axiosPut(url, params);
}


export const verifyOTP = (otp, email) => {
  const url = `${baseUrl}admin/otp/verify`;
  const params = { otp, email }
  return axiosPost(url, params);
};

export const resendOTP = (email, isResend) => {
  const url = `${baseUrl}admin/otp/resend`;
  const params = {
    email: email,
    isResend: isResend
  }
  return axiosPost(url, params);
};

export const reSendOtp = (email) => {
  const url = `${baseUrl}admin/otp-resend`;
  const params = {
    email: email
  }
  return axiosPost(url, params)
}

export const checkModuleExits = (params) => {
  const url = `${baseUrl}modules/checkExists`;
  return axiosGet(url, params);
};

export const checkSurveyExits = (params) => {
  const url = `${baseUrl}surveys/checkExists`;
  return axiosGet(url, params);
};

export const checkNotificationExists = (params) => {
  const url = `${baseUrl}notifications/checkExists`;
  return axiosGet(url, params);
};

export const getManagerIdByCompany = (companyId) => {
  const params = {}
  const url = `${baseUrl}client/employee-id-list/${companyId}`;
  return axiosGet(url, params)
}

export const getManagerNameByEmployeeId = (employeeId, params) => {
  const url = `${baseUrl}client/manager-name/${employeeId}`;
  return axiosGet(url, params)
}
export const getUserMultipleCompany = (array) => {
  const params = {
    companyIds: array
  }
  const url = `${baseUrl}client/multiple-company`;
  return axiosGet(url, params)
}

export const getRewardMultipleCompany = (array) => {
  const params = {
    companyIds: array
  }
  const url = `${baseUrl}reward/by-multiple-company`;
  return axiosGet(url, params)
}

export const getLocationMultipleCompany = (array) => {
  const params = {
    companyIds: array
  }
  const url = `${baseUrl}location/by-multiple-company`;
  return axiosGet(url, params)
}

export const getSurveyMultipleCompany = (array) => {
  const params = {
    companyIds: array
  }
  const url = `${baseUrl}surveys/by-multiple-company`;
  return axiosGet(url, params)
}

export const getNotificationsMultipleCompany = (array) => {
  const params = {
    companyIds: array
  }
  const url = `${baseUrl}notifications/by-multiple-company`;
  return axiosGet(url, params)
}

export const getQuestionBySurvey = (array) => {
  const params = {
    surveyId: array
  };
  const url = `${baseUrl}question/by-survey-id`;
  return axiosGet(url, params)
}
export const getMangerIdByMultipleCompany = (companyId) => {
  const params = { companyId: companyId }
  const url = `${baseUrl}client/employee-id-list-multiple-manager`;
  return axiosGet(url, params)
}

export const allowContentCompany = (data, id) => {
  const params = { ...data }
  const url = `${baseUrl}company/allow-content/${id}`;
  return axiosPut(url, params)
}
export const getVideos = (params = {}) => {
  const url = `${baseUrl}videos`;
  return axiosGet(url, params)
}

export const getInterestList = (params = {}) => {
  const url = `${baseUrl}interest/`;
  return axiosGet(url, params);
};

export const getModuleHardCodeList = (params = {}) => {
  const url = `${baseUrl}modules/hard-code`;
  return axiosGet(url, params);
};

export const updateInterest = (params = {}) => {
  const url = `${baseUrl}interest/`;
  return axiosPut(url, params);
};
export const checkRingoExists = (params) => {
  const url = `${baseUrl}ringos/checkExists`;
  return axiosGet(url, params);
};
export const addRingo = (values) => {
  const url = `${baseUrl}ringos/`;
  const params = {
    ringoInfo: {
      ...values,
      jobCategory: JSON.stringify(values.jobCategory),
      jobTitle: JSON.stringify(values.jobTitle),
      targeting_users: JSON.stringify(values.targeting_users),
      effort: JSON.stringify(values.effort),
    },
  };
  return axiosPost(url, params);
}
export const GetRingoById = (id) => {
  const url = `${baseUrl}ringos/${id}`;
  const params = {};
  return axiosGet(url, params);
};
export const UpdateRingo = (values) => {
  const url = `${baseUrl}ringos/${values.ringoId}`;
  const params = {
    ringoInfo: {
      ...values,
      jobCategory: JSON.stringify(values.jobCategory),
      jobTitle: JSON.stringify(values.jobTitle),
      targeting_users: JSON.stringify(values.targeting_users),
      effort: JSON.stringify(values.effort),
    },
  };
  return axiosPut(url, params);
};
export const checkProgramExits = (params) => {
  const url = `${baseUrl}programs/checkExists`;
  return axiosGet(url, params);
};

export const addProgram = (values) => {
  const url = `${baseUrl}programs/`;
  const params = {
    programInfo: {
      ...values,
      jobCategory: JSON.stringify(values.jobCategory),
      jobTitle: JSON.stringify(values.jobTitle),
      targeting_users: JSON.stringify(values.targeting_users),
      effort: JSON.stringify(values.effort),
    },
  };
  return axiosPost(url, params);
}

export const GetProgramList = (params = {}) => {
  const url = `${baseUrl}programs/`;
  return axiosGet(url, params);
};

export const GetProgramById = (id) => {
  const url = `${baseUrl}programs/${id}`;
  const params = {};
  return axiosGet(url, params);
};

export const UpdateProgram = (values) => {
  const url = `${baseUrl}programs/${values.programId}`;
  const params = {
    programInfo: {
      ...values,
      jobCategory: JSON.stringify(values.jobCategory),
      jobTitle: JSON.stringify(values.jobTitle),
      targeting_users: JSON.stringify(values.targeting_users),
      effort: JSON.stringify(values.effort),
    },
  };
  return axiosPut(url, params);
};

export const delProgramById = (id) => {
  const url = `${baseUrl}programs/${id}`;
  const params = {};
  return axiosDelete(url, params);
};

export const deleteMultiProgram = (list) => {
  const url = `${baseUrl}programs/multiple`;
  const params = {
    programIds: list,
  };
  return axiosDelete(url, params);
};

export const checkGroupExist = (params) => {
  const url = `${baseUrl}groups/checkExists`;
  return axiosGet(url, params);
};

export const addGroup = (values) => {
  const url = `${baseUrl}groups/`;
  const params = {
    groupInfo: {
      ...values,
    },
  };
  return axiosPost(url, params);
}

export const getGroupList = (params = {}) => {
  const url = `${baseUrl}groups/`;
  return axiosGet(url, params);
};

export const getGroupById = (id) => {
  const url = `${baseUrl}groups/${id}`;
  const params = {};
  return axiosGet(url, params);
};

export const updateGroup = (values) => {
  const url = `${baseUrl}groups/${values.groupId}`;
  const params = {
    groupInfo: {
      ...values,
    },
  };
  return axiosPut(url, params);
};

export const deleteMultiGroup = (list) => {
  const url = `${baseUrl}groups/multiple`;
  const params = {
    groupIds: list,
  };
  return axiosDelete(url, params);
};

export const getManagerByMultipleCompany = (array) => {
  const params = {
    companyIds: array
  }
  const url = `${baseUrl}client/multiple-company-manager`;
  return axiosGet(url, params)
}
//Admin
export const getAdminList = (params) => {
  const url = `${baseUrl}admin/`;
  return axiosGet(url, params);
};

export const getAdminById = (id) => {
  const url = `${baseUrl}admin/${id}`;
  const params = {}
  return axiosGet(url, params)
}

export const updateAdminById = (values) => {
  const url = `${baseUrl}admin/${values.adminId}`;
  const params = {
    adminInfo: {
      ...values,
    },
  };
  return axiosPut(url, params);
}

export const sendOtp = (phone, id) => {
  const url = `${baseUrl}admin/send-otp/${id}`;
  const params = { phone }
  return axiosPost(url, params);
};

export const sendOtpUpdateAdmin = (id) => {
  const url = `${baseUrl}admin/send-otp-update-admin/${id}`;
  const params = {};
  return axiosPost(url, params);
}

export const verifyOtpUpdateAdmin = (id, otp) => {
  const url = `${baseUrl}admin/verify-admin-update/${id}`;
  const params = {
    otp: otp
  }
  return axiosPost(url, params)
}

export const crAdminUpdate = (values) => {
  const url = `${baseUrl}admin/crAdminUpdate/${values.adminId}`;
  const params = {
    adminInfo: {
      ...values,
    },
  };
  return axiosPut(url, params);
}

export const changePasswordAdmin = (data, id) => {
  const url = `${baseUrl}admin/change-password-admin/${id}`;
  return axiosPost(url, data);
};

export const delAdminList = (list) => {
  const url = `${baseUrl}admin/multiple`;
  const params = {
    adminIds: list,
  };
  return axiosDelete(url, params);
};

export const getUserMultipleCompanyForGroup = (array) => {
  const params = {
    companyIds: array
  }
  const url = `${baseUrl}client/multiple-company-for-groups`;
  return axiosGet(url, params)
}

export const checkTokenResetPassword = (token) => {
  const response = axios.get(`${baseUrl}admin/reset?token=${token}`, {})
  return response
};

export const changePasswordForgot = (values) => {
  const params = {
    ...values,
  }
  const response = axios.put(`${baseUrl}admin/reset-password`, params)
  return response
}

export const getVideosByMultipleCompany = (array) => {
  const url = `${baseUrl}videos/by-multiple-company`;
  const params = { companyIds: array }
  return axiosGet(url, params)
}

export const pushNotificationWhenSendingMsg = (data) => {
  const url = `${baseUrl}chat/noti`;
  return axiosPost(url, data);
}

export const getTemplatesList = (params = {}) => {
  const url = `${baseUrl}templates/`;
  return axiosGet(url, params);
};

export const getTemplateById = (id, contentType) => {
  const url = `${baseUrl}templates/${id}`;
  const params = { contentType }
  return axiosGet(url, params)
}

export const addTemplate = (values) => {
  const url = `${baseUrl}templates/create`;
  const params = {
    ...values,
    // jobCategory: JSON.stringify(values.jobCategory),
    // jobTitle: JSON.stringify(values.jobTitle),
    // targeting_users: JSON.stringify(values.targeting_users),
    effort: JSON.stringify(values.effort),
  };
  return axiosPost(url, params);
};

export const deleteTemplate = (id, contentType) => {
  const url = `${baseUrl}templates/${id}`;
  const params = { contentType }

  return axiosDelete(url, params);
};

export const updateTemplate = (id, values) => {
  const url = `${baseUrl}templates/${id}`;
  const params = {
    ...values,
    // jobCategory: JSON.stringify(values.jobCategory),
    // jobTitle: JSON.stringify(values.jobTitle),
    // targeting_users: JSON.stringify(values.targeting_users),
    effort: JSON.stringify(values.effort),
  };
  return axiosPatch(url, params);
}; 

export const checkExistPhoneNumber = (params) => {
  const url = `${baseUrl}admin/check-exist-phone`;
  return axiosGet(url, params);
}

export const getSurveyProgress = (companyId, selectedSurvey, params = {}) => {
  const url = `${baseUrl}surveys/surveyReport/${companyId}?selectedSurvey=${selectedSurvey}`;
  return axiosGet(url, params);
}

export const getSurveyRingoList = (companyId, params = {}) => {
  const url = `${baseUrl}surveys/surveyRingoList/${companyId}`;
  return axiosGet(url, params);
}

export const getModuleProgress = (companyId, params = {}) => {
  const url = `${baseUrl}modules/moduleReport/${companyId}`;
  return axiosGet(url, params);
}

export const GetRingoList = (params = {}) => {
  const url = `${baseUrl}ringos/`;
  return axiosGet(url, params);
};

export const delRingoList = (list) => {
  const url = `${baseUrl}ringos/multiple`;
  const params = {
    ringoIds: list,
  };
  return axiosDelete(url, params);
};

export const GetAuditLogsList = (params = {}) => {
  const url = `${baseUrl}audit-logs/get-logs`;
  return axiosGet(url, params);
};

export const GetLogsEventTypes = (params = {}) => {
  const url = `${baseUrl}audit-logs/event-types`;
  return axiosGet(url, params);
};

export const GetOneModuleRingo = (moduleId, params = {}) => {
  const url = `${baseUrl}modules/getOneModuleRingo/${moduleId}`;
  return axiosGet(url, params);
};

export const GetListModuleRingo = ( params = {}) => {
  const url = `${baseUrl}modules/listModuleRingo`;
  return axiosGet(url, params);
};

export const getEmployees = (params = {}) => {
  const url = `${baseUrl}employee`;
  return axiosGet(url, params);
};
export const getEmployeeById = (id,userId) => {
  const url = `${baseUrl}employee/getOne?${userId ? "userId" : "adminId"}=${id}`;
  return axiosGet(url);
};
export const delEmployeeList = (adminIds,userIds) => {
  const url = `${baseUrl}employee/multiple`;
  const params = {
    adminIds,
    userIds
  };
  return axiosDelete(url, params);
};